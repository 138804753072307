<template>
  <!-- 用于减少margin -->
  <div style=";margin: -15px -15px;background-color: #f0f2f5;">
    <!-- 日期查询框 -->
    <div class="datePicker">
      <div class="date">
        <span>日期：</span>
        <a-range-picker @change="ondateChange" class="rangePicker" :value="createValue"/>
        <a-input style="width: 200px;margin-right:2%;" class="input" placeholder="请输入患者姓名/医生姓名" @keyup.enter.native="searchBtn()" v-model="searchName"
          >
         </a-input>
        <a-button type="primary" @click="searchBtn">查询</a-button>
        <a-button style="margin-left: 15px" @click="returnBtn()">重置</a-button>
      </div>
    </div>
      <!-- table -->
      <div class="table" style="background-color: #fff;">
        <a-table :columns="tableColumns" :pagination="false" :data-source="tableData" 
        :row-key="(record) => record.id">
        </a-table>
        <!-- 分页 -->
       <div style="background-color: #fff;width:100%;height: 100px;">
         <a-pagination style="float: right;margin-top: 25px;" show-quick-jumper show-size-changer :page-size.sync="PpageSize"
         @change="pageChange" @showSizeChange="pageChange"  v-model="pagination.current" :total=pagination.total
         />
       </div>
      </div>
   </div>
</template>

<script>
import {
  getCourse
} from "@/service/patientManagement"
export default {
  data() {
    return {
      PstartTime: '',  //用于列表传参
      PendTime: '',
      PpageNum: '',
      PpageSize: 10, 
      tableData: [],  //用于列表数据
      searchName: '' , //传输入框的数据
      pagination: {   //用于分页
      total: 0,
      current: 1,
      pageSize: 10,
      },
      createValue:[],  //用于重置日期框
      tableColumns: [
        {
          title: '医生姓名',
          dataIndex: 'doctorName',
          // scopedSlots: { customRender: 'titles' },
          // width: '40%'
        },
        {
          title: '医院',
          dataIndex: 'hospital',
          // width: '20%'
        },
        {
          title: '科室',
          dataIndex: 'department',
          // width: '20%',
          // scopedSlots: { customRender: 'name' },
        },
        {
          title: '职称',
          dataIndex: 'job',
          // width: '20%'
        },
        {
          title: '患者姓名/昵称',
          dataIndex: 'patientName',
          // width: '20%'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          // width: '20%'
        },
        {
          title: '病程类型',
          dataIndex: 'type',
          // width: '20%'
        },
        {
          title: '创建日期',
          dataIndex: 'createdTime',
          // width: '20%'
        },
      ],   
    }
  },
  created() {
    this.getCourse()
  },
  methods: {
    // 重置
    returnBtn() {
      this.searchName = ''  //输入框
      this.pagination.current = 1  //默认显示
      this.PpageSize = 10  //行数
      this.createValue = ''  //重置日期框
      this.PpageNum = 1
      this.PstartTime = ''
      this.PendTime = ''

      this.getCourse('', '', '', this.PpageNum,this.PpageSize)
    },
    // 1.1传日期参数
    async ondateChange(date, dateString) {
      this.createValue = date
      this.PstartTime = dateString[0]
      this.PendTime = dateString[1]
      console.log(this.PstartTime,this.PendTime)
      this.getCourse()
    },
    // 1.2传输入框的参数
    searchBtn() {
      this.PpageNum = 1;
      this.PpageSize = 10;
      this.pagination.current = 1
      this.getCourse('', '',this.searchName, '', 10)
    },
    // 1.3传分页参数
    pageChange(page_no, page_size) {
      console.log("怎么会呢",page_size)
      // console.log(page_size_options)  
      this.PpageNum = page_no
      this.PpageSize = page_size
      // console.log(this.PpageNum)
      // console.log(this.PpageSize)
      this.getCourse()
    },
    //1.获取病程列表
    async getCourse(startTime,endTime,name,pageNum,pageSize) { 
      // var startTime = this.PstartTime; var endTime = this.PendTime; var name = this.Pname; var pageNum = this.PpageNum; var pageSize = this.PpageSize
      var startTime = this.PstartTime ; var endTime = this.PendTime ; var name = this.searchName; var pageNum = this.PpageNum ; var pageSize = this.PpageSize
      const response = await getCourse(startTime,endTime,name,pageNum,pageSize)
      // console.log(target_id, target_type, page_num, page_size, this.commentDetailParameter.startTime, this.commentDetailParameter.endTime)
      if (response.code === 0) {
        console.log(response)
        this.tableData = response.data.list
        this.pagination.total = response.data.total
      } else {
        this.$message.warning(response.message)
      }
    },
  }


}
</script>

<style lang="scss" scoped>
.datePicker{
 background-color: #fff;
 width: 100%;
 height: 64px;
 margin-bottom: 20px;
 display: flex;
 justify-content: space-between;
 align-items: center; 
 padding-left: 2%;
}
.date {
  /* margin: auto; */
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color:#272727;
  .rangePicker{
    margin-right:4%;
  }
}

</style>